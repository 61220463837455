
//
// bootstrap-taginput.scss
//

/* Bootstrap tagsinput */
.bootstrap-tagsinput {
  box-shadow: none;
  padding: 4px 7px 4px;
  width: 100%;
  background: $input-bg;
  border-color: $input-border-color;

  input{
    color: $gray-600;
  }
  .label-info {
    background-color: $primary;
    display: inline-block;
    font-size: 13px;
    margin: 3px 1px;
    padding: 0 5px;
    border-radius: 3px;
    font-weight: $font-weight-medium;
  }
}